import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Heading,
  VStack,
  Button,
  useBreakpointValue,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  SimpleGrid,
  Icon,
  IconButton,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  FormErrorMessage,
  Switch,
  Badge,
  Spinner,
} from "@chakra-ui/react";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import DataTable from "react-data-table-component";
import Navbar from "../components/Navbar";
import {
  FaUsers,
  FaStore,
  FaHandshake,
  FaDollarSign,
  FaPlus,
  FaTrash,
  FaEdit,
  FaLink,
  FaPaperPlane,
} from "react-icons/fa";
import { UserAuth } from "../contexts/AuthContext";
import axios from "axios";
import { apiUrl } from "../config";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { ArrowBackIcon } from "@chakra-ui/icons";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  isAdmin: Yup.boolean(),
});

const CampaignHome = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const { user } = UserAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [campaignName, setCampaignName] = useState("");
  const [campaignStats, setCampaignStats] = useState({
    agentCount: 0,
    businessCount: 0,
    totalAmountSold: 0,
    totalSalesCount: 0,
  });
  const toast = useToast();

  const buttonSize = useBreakpointValue({ base: "sm", md: "sm" });
  const idToken = sessionStorage.getItem("idToken");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [fundraiserToRemove, setFundraiserToRemove] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingAgent, setEditingAgent] = useState(null);
  const [businessToDelete, setBusinessToDelete] = useState(null);
  const [isDeleteBusinessModalOpen, setIsDeleteBusinessModalOpen] =
    useState(false);
  const [organizationId, setOrganizationId] = useState(null);
  const [loadingBusinesses, setLoadingBusinesses] = useState({});
  const [loadingCustomers, setLoadingCustomers] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;

      // Fetch campaign name and active status
      const campaignDoc = await getDoc(doc(db, "campaigns", campaignId));
      if (campaignDoc.exists()) {
        const campaignData = campaignDoc.data();
        setCampaignName(campaignData.name.en);
        setIsActive(campaignData.active || false);
        setOrganizationId(campaignData.organizationId);
      }

      // Fetch businesses
      const businessesQuery = query(
        collection(db, "businesses"),
        where("campaignId", "==", campaignId)
      );
      const businessesSnapshot = await getDocs(businessesQuery);

      // Get all unique agent IDs from businesses
      const agentIds = [
        ...new Set(businessesSnapshot.docs.map((doc) => doc.data().agentId)),
      ];

      // Fetch agent names
      const agentNames = {};
      for (const agentId of agentIds) {
        if (agentId) {
          // Check if agentId exists
          const agentDoc = await getDoc(
            doc(db, `campaigns/${campaignId}/agents`, agentId)
          );
          if (agentDoc.exists()) {
            const agentData = agentDoc.data();
            agentNames[agentId] = `${agentData.firstName || ""} ${
              agentData.lastName || ""
            }`.trim();
          }
        }
      }

      const businessesData = businessesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        addedBy: agentNames[doc.data().agentId] || "Unknown", // Add the agent name
      }));
      setBusinesses(businessesData);

      // Fetch customers
      const customersQuery = query(
        collection(db, "customers"),
        where("campaignId", "==", campaignId)
      );
      const customersSnapshot = await getDocs(customersQuery);
      setCustomers(
        customersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );

      // Fetch agents
      const agentsQuery = query(
        collection(db, `campaigns/${campaignId}/agents`)
      );
      const agentsSnapshot = await getDocs(agentsQuery);
      const agentsData = agentsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Fetch pending invites
      const pendingInvitesQuery = query(
        collection(db, "pendingInvites"),
        where("campaignId", "==", campaignId),
        where("type", "==", "campaign"),
        where("status", "==", "pending")
      );
      const pendingInvitesSnapshot = await getDocs(pendingInvitesQuery);
      const pendingInvitesData = pendingInvitesSnapshot.docs.map((doc) => ({
        id: doc.id,
        email: doc.data().email,
        isAdmin: doc.data().isAdmin,
        status: "pending",
        saleCount: 0,
        saleAmount: 0,
      }));

      // Combine agents and pending invites
      setAgents([...agentsData, ...pendingInvitesData]);

      // Calculate campaign stats (only count actual agents, not pending invites)
      const totalAmountSold = agentsData.reduce(
        (sum, agent) => sum + (agent.saleAmount || 0),
        0
      );
      const totalSalesCount = agentsData.reduce(
        (sum, agent) => sum + (agent.saleCount || 0),
        0
      );

      setCampaignStats({
        agentCount: agentsData.length + pendingInvitesData.length, // Include pending invites in count
        businessCount: businessesData.length,
        totalAmountSold,
        totalSalesCount,
      });

      // Check if the current user is an admin
      const agentRef = doc(db, `campaigns/${campaignId}/agents`, user.uid);
      const agentDoc = await getDoc(agentRef);
      if (agentDoc.exists() && agentDoc.data().isAdmin) {
        setIsAdmin(true);
      }
    };

    fetchData();
  }, [campaignId, user]);

  const getBusinessStatus = (business) => {
    if (business.approved) {
      return { label: "Approved", colorScheme: "green" };
    }
    if (business.sentOut) {
      return { label: "Under Review", colorScheme: "purple" };
    }
    if (business.selfCompletion) {
      return { label: "Submission Pending", colorScheme: "yellow" };
    }
    return { label: "Draft", colorScheme: "gray" };
  };

  const businessColumns = [
    {
      name: "Name",
      selector: (row) => row.name?.en || "",
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Added by",
      selector: (row) => row.addedBy,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.createdAt?.toDate().toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => {
        const status = getBusinessStatus(row);
        return (
          <Badge colorScheme={status.colorScheme} variant="subtle">
            {status.label}
          </Badge>
        );
      },
      sortable: true,
      width: "150px",
    },
    {
      name: "Actions",
      cell: (row) => (
        <HStack spacing={2}>
          {isAdmin && (
            <>
              <IconButton
                icon={<FaEdit />}
                aria-label="Edit Business"
                size="sm"
                variant="ghost"
                colorScheme="green"
                onClick={() => navigate(`/edit-business?businessId=${row.id}`)}
              />
              <IconButton
                icon={<FaTrash />}
                aria-label="Delete Business"
                size="sm"
                variant="ghost"
                colorScheme="red"
                onClick={() => initiateDeleteBusiness(row)}
              />
              <IconButton
                icon={
                  loadingBusinesses[row.id] ? (
                    <Spinner size="sm" />
                  ) : (
                    <FaPaperPlane />
                  )
                }
                aria-label="Send for Approval"
                size="sm"
                variant="ghost"
                colorScheme="blue"
                onClick={() => handleSendForApproval(row)}
                isDisabled={row.approved || loadingBusinesses[row.id]}
                title={row.approved ? "Already approved" : "Send for approval"}
              />
            </>
          )}
        </HStack>
      ),
      width: "200px",
    },
  ];

  const customerColumns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Purchase Date",
      selector: (row) => row.createdAt?.toDate().toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <IconButton
          icon={
            loadingCustomers[row.id] ? <Spinner size="sm" /> : <FaPaperPlane />
          }
          aria-label="Resend Email"
          size="sm"
          variant="ghost"
          colorScheme="blue"
          onClick={() => handleResendEmail(row.id)}
          isDisabled={loadingCustomers[row.id]}
          title="Resend purchase email"
        />
      ),
      width: "100px",
    },
  ];

  const copyToClipboard = (agentId) => {
    const url = `https://app.coupal.io/support/${campaignId}/${agentId}`;
    navigator.clipboard.writeText(url).then(() => {
      toast({
        title: "URL Copied",
        description: "The agent's URL has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const copyNewCustomerUrl = () => {
    const url = `https://app.coupal.io/support/${campaignId}`;
    navigator.clipboard.writeText(url).then(() => {
      toast({
        title: "URL Copied",
        description: "The new customer URL has been copied to your clipboard.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const agentColumns = [
    {
      name: "Name",
      selector: (row) => {
        if (row.status === "pending") {
          return (
            <Badge colorScheme="yellow" variant="subtle">
              Pending Invite
            </Badge>
          );
        }
        return `${row.firstName || ""} ${row.lastName || ""}`.trim();
      },
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "250px",
    },
    {
      name: "Role",
      selector: (row) => (row.isAdmin ? "Admin" : "Team Member"),
      sortable: true,
    },
    {
      name: "Sales",
      selector: (row) => row.saleCount || 0,
      sortable: true,
    },
    {
      name: "Sales Amount",
      selector: (row) => `$${(row.saleAmount || 0).toFixed(2)}`,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <HStack spacing={2}>
          {row.status !== "pending" && (
            <IconButton
              icon={<FaLink />}
              aria-label="Copy URL"
              size="sm"
              variant="ghost"
              colorScheme="blue"
              onClick={() => copyToClipboard(row.id)}
            />
          )}
          {isAdmin && (
            <>
              {row.status !== "pending" ? (
                <>
                  <IconButton
                    icon={<FaEdit />}
                    aria-label="Edit Team Member"
                    size="sm"
                    variant="ghost"
                    colorScheme="green"
                    onClick={() => initiateEditAgent(row)}
                  />
                  <IconButton
                    icon={<FaTrash />}
                    aria-label="Remove Team Member"
                    size="sm"
                    variant="ghost"
                    colorScheme="red"
                    onClick={() => initiateRemoveAgent(row)}
                  />
                </>
              ) : (
                <IconButton
                  icon={<FaTrash />}
                  aria-label="Delete Invitation"
                  size="sm"
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => deletePendingInvite(row)}
                />
              )}
            </>
          )}
        </HStack>
      ),
      width: "200px",
    },
  ];

  const initiateRemoveAgent = (agent) => {
    setFundraiserToRemove(agent);
    setIsRemoveModalOpen(true);
  };

  const confirmRemoveAgent = async () => {
    if (!fundraiserToRemove) return;

    try {
      await axios.delete(
        `${apiUrl}/campaigns/${campaignId}/agents/${fundraiserToRemove.id}`,
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      // Update local state
      setAgents(agents.filter((agent) => agent.id !== fundraiserToRemove.id));
      setCampaignStats((prev) => ({
        ...prev,
        agentCount: prev.agentCount - 1,
      }));

      toast({
        title: "Success",
        description: "Team Member removed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setIsRemoveModalOpen(false);
      setFundraiserToRemove(null);
    } catch (error) {
      console.error("Error removing fundraiser:", error);
      toast({
        title: "Error",
        description: "Failed to remove fundraiser. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleAddAgent = async (values, { setSubmitting, resetForm }) => {
    try {
      const idToken = sessionStorage.getItem("idToken");
      const response = await axios.post(
        `${apiUrl}/campaigns/${campaignId}/agents`,
        values,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      // If the response indicates a pending invite
      if (response.data.status === "pending") {
        // Add a temporary row to the agents list with pending status
        setAgents((prevAgents) => [
          ...prevAgents,
          {
            id: response.data.inviteId, // Use the invite ID as temporary ID
            email: values.email,
            firstName: "Pending",
            lastName: "Invitation",
            isAdmin: values.isAdmin,
            saleCount: 0,
            saleAmount: 0,
            status: "pending",
          },
        ]);
      } else {
        // Add the new agent to the list
        setAgents((prevAgents) => [...prevAgents, response.data.agent]);
      }

      setIsModalOpen(false);
      resetForm();
      toast({
        title:
          response.data.status === "pending"
            ? "Invitation Sent"
            : "Team Member Added",
        description:
          response.data.status === "pending"
            ? "An invitation has been sent to the email address"
            : "The team member has been added successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Update campaign stats
      setCampaignStats((prev) => ({
        ...prev,
        agentCount: prev.agentCount + 1,
      }));
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.error || "Failed to add team member",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleBackClick = () => {
    if (organizationId) {
      navigate(`/organization/${organizationId}`);
    }
  };

  const fundraisersStat = (
    <Stat bg="white" p={4} borderRadius="lg" boxShadow="md" position="relative">
      <Flex justifyContent="space-between" alignItems="flex-start">
        <StatLabel fontSize="lg">Team Members</StatLabel>
        <Icon as={FaUsers} boxSize={7} color="black" />
      </Flex>
      <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
        <StatNumber fontSize="3xl">{campaignStats.agentCount}</StatNumber>
        {isAdmin && (
          <IconButton
            size="sm"
            onClick={() => setIsModalOpen(true)}
            icon={<Icon as={FaPlus} />}
            aria-label="Add Team Member"
          />
        )}
      </Flex>
    </Stat>
  );

  const addAgentModal = (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Team Member</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ email: "", isAdmin: false }}
          validationSchema={validationSchema}
          onSubmit={handleAddAgent}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <VStack spacing={4}>
                  <Field name="email">
                    {({ field, form }) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                        isRequired
                      >
                        <FormLabel>Email</FormLabel>
                        <Input
                          {...field}
                          placeholder="Enter team member's email"
                        />
                        <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field name="isAdmin">
                    {({ field }) => (
                      <FormControl>
                        <Checkbox {...field}>Make Admin</Checkbox>
                      </FormControl>
                    )}
                  </Field>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  mr={3}
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={props.isSubmitting}
                >
                  Add Team Member
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );

  const handleActiveToggle = async () => {
    try {
      await axios.put(
        `${apiUrl}/campaigns/${campaignId}/toggleActive`,
        {
          active: !isActive,
        },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      setIsActive(!isActive);
      toast({
        title: "Success",
        description: `Campaign ${
          !isActive ? "activated" : "deactivated"
        } successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error toggling campaign status:", error);
      setErrorMessage(
        error.response?.data?.message || "Failed to update campaign status"
      );
      setIsErrorModalOpen(true);
    }
  };

  const handleEditAgent = async (values, actions) => {
    try {
      const response = await axios.put(
        `${apiUrl}/campaigns/${campaignId}/agents/${editingAgent.id}`,
        { isAdmin: values.isAdmin },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      // Update local state
      setAgents(
        agents.map((agent) =>
          agent.id === editingAgent.id
            ? { ...agent, isAdmin: values.isAdmin }
            : agent
        )
      );

      toast({
        title: "Success",
        description: "Team member updated successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      actions.setSubmitting(false);
      setIsEditModalOpen(false);
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.error || "Failed to update team member",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      actions.setSubmitting(false);
    }
  };

  const initiateEditAgent = (agent) => {
    setEditingAgent(agent);
    setIsEditModalOpen(true);
  };

  const editAgentModal = (
    <Modal isOpen={isEditModalOpen} onClose={() => setIsEditModalOpen(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Team Member</ModalHeader>
        <ModalCloseButton />
        <Formik
          initialValues={{ isAdmin: editingAgent?.isAdmin || false }}
          onSubmit={handleEditAgent}
        >
          {(props) => (
            <Form>
              <ModalBody>
                <VStack spacing={4} align="stretch">
                  <Text>
                    {editingAgent?.firstName} {editingAgent?.lastName}
                  </Text>
                  <Field name="isAdmin">
                    {({ field }) => (
                      <FormControl>
                        <Checkbox {...field} isChecked={field.value}>
                          Make Admin
                        </Checkbox>
                      </FormControl>
                    )}
                  </Field>
                </VStack>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="ghost"
                  mr={3}
                  onClick={() => setIsEditModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  isLoading={props.isSubmitting}
                >
                  Save Changes
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );

  const deletePendingInvite = async (agent) => {
    try {
      await axios.delete(`${apiUrl}/pendingInvites`, {
        headers: { Authorization: `Bearer ${idToken}` },
        data: {
          type: "campaign",
          id: campaignId,
          email: agent.email,
        },
      });

      // Update local state
      setAgents(agents.filter((a) => a.id !== agent.id));
      setCampaignStats((prev) => ({
        ...prev,
        agentCount: prev.agentCount - 1,
      }));

      toast({
        title: "Success",
        description: "Invitation deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error deleting invitation:", error);
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Failed to delete invitation",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const initiateDeleteBusiness = (business) => {
    setBusinessToDelete(business);
    setIsDeleteBusinessModalOpen(true);
  };

  const confirmDeleteBusiness = async () => {
    if (!businessToDelete) return;

    try {
      await axios.delete(`${apiUrl}/businesses/${businessToDelete.id}`, {
        headers: { Authorization: `Bearer ${idToken}` },
      });

      // Update local state
      setBusinesses(businesses.filter((b) => b.id !== businessToDelete.id));
      setCampaignStats((prev) => ({
        ...prev,
        businessCount: prev.businessCount - 1,
      }));

      toast({
        title: "Success",
        description: "Business deleted successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setIsDeleteBusinessModalOpen(false);
      setBusinessToDelete(null);
    } catch (error) {
      console.error("Error deleting business:", error);
      toast({
        title: "Error",
        description: "Failed to delete business. Please try again.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleSendForApproval = async (business) => {
    setLoadingBusinesses((prev) => ({ ...prev, [business.id]: true }));
    try {
      const idToken = sessionStorage.getItem("idToken");
      await axios.post(
        `${apiUrl}/generate-business-link`,
        {
          email: business.email,
          businessId: business.id,
          type: "approval",
          campaignId: campaignId,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      toast({
        title: "Success",
        description: "Approval request sent to business",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error sending approval request:", error);
      toast({
        title: "Error",
        description:
          error.response?.data?.error || "Failed to send approval request",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingBusinesses((prev) => ({ ...prev, [business.id]: false }));
    }
  };

  const handleResendEmail = async (customerId) => {
    setLoadingCustomers((prev) => ({ ...prev, [customerId]: true }));
    try {
      await axios.post(
        `${apiUrl}/send-customer-purchase-email`,
        { customerId },
        {
          headers: { Authorization: `Bearer ${idToken}` },
        }
      );

      toast({
        title: "Success",
        description: "Purchase email resent successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error resending email:", error);
      toast({
        title: "Error",
        description: error.response?.data?.error || "Failed to resend email",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingCustomers((prev) => ({ ...prev, [customerId]: false }));
    }
  };

  return (
    <>
      <Navbar />
      <Box minH="calc(100vh - 60px)" bg="primaryBackground" pt="60px" px={4}>
        <VStack
          align="stretch"
          maxWidth="1200px"
          margin="0 auto"
          // pt={8}
          spacing={8}
        >
          <Flex
            direction={{ base: "column", md: "row" }}
            justify={{ base: "center", md: "space-between" }}
            align={{ base: "center", md: "flex-start" }}
            gap={4}
          >
            <Box textAlign={{ base: "center", md: "left" }}>
              <Heading as="h1" size="lg" color="heading">
                Campaign Dashboard
              </Heading>
              <Text color="text" fontSize="xl">
                {campaignName}
              </Text>
            </Box>
            <VStack
              spacing={4}
              align={{ base: "center", sm: "flex-end" }}
              width={{ base: "100%", sm: "auto" }}
            >
              <Flex
                direction={{ base: "column", sm: "row" }}
                gap={2}
                width="100%"
              >
                <Button
                  onClick={copyNewCustomerUrl}
                  size={buttonSize}
                  variant="primary"
                  width={{ base: "full", sm: "auto" }}
                >
                  Copy Purchase URL
                </Button>
                {isAdmin && (
                  <Button
                    onClick={() => navigate(`/edit-campaign/${campaignId}`)}
                    size={buttonSize}
                    variant="secondary"
                    width={{ base: "full", sm: "auto" }}
                  >
                    Edit Campaign
                  </Button>
                )}
              </Flex>
              {isAdmin && (
                <Flex
                  align="center"
                  gap={2}
                  width={{ base: "100%", sm: "auto" }}
                  justify={{ base: "center", sm: "flex-end" }}
                >
                  <Switch
                    isChecked={isActive}
                    onChange={handleActiveToggle}
                    isDisabled={!isAdmin}
                    sx={{
                      "span.chakra-switch__track[data-checked]": {
                        backgroundColor: "primaryHighlight",
                      },
                      "span.chakra-switch__thumb": {
                        backgroundColor: "white",
                      },
                    }}
                  />
                  <Text color="gray.600">
                    {isActive ? "Active" : "Inactive"}
                  </Text>
                </Flex>
              )}
            </VStack>
          </Flex>

          <SimpleGrid
            columns={{ base: 1, sm: 2, lg: 4 }}
            spacing={6}
            width="100%"
          >
            <Stat
              bg="white"
              p={4}
              borderRadius="lg"
              boxShadow="md"
              position="relative"
            >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <StatLabel fontSize="lg">Total Amount Sold</StatLabel>
                <Icon as={FaDollarSign} boxSize={7} color="black" />
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
                <StatNumber fontSize="3xl">
                  ${campaignStats.totalAmountSold.toFixed(2)}
                </StatNumber>
              </Flex>
            </Stat>
            <Stat
              bg="white"
              p={4}
              borderRadius="lg"
              boxShadow="md"
              position="relative"
            >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <StatLabel fontSize="lg">Supporters</StatLabel>
                <Icon as={FaHandshake} boxSize={7} color="black" />
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
                <StatNumber fontSize="3xl">
                  {campaignStats.totalSalesCount}
                </StatNumber>
              </Flex>
            </Stat>
            <Stat
              bg="white"
              p={4}
              borderRadius="lg"
              boxShadow="md"
              position="relative"
            >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <StatLabel fontSize="lg">Businesses</StatLabel>
                <Icon as={FaStore} boxSize={7} color="black" />
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
                <StatNumber fontSize="3xl">
                  {campaignStats.businessCount}
                </StatNumber>
                <IconButton
                  size="sm"
                  onClick={() =>
                    navigate(`/business-type-selection/${campaignId}`)
                  }
                  icon={<Icon as={FaPlus} />}
                  aria-label="Add Business"
                />
              </Flex>
            </Stat>
            <Stat
              bg="white"
              p={4}
              borderRadius="lg"
              boxShadow="md"
              position="relative"
            >
              <Flex justifyContent="space-between" alignItems="flex-start">
                <StatLabel fontSize="lg">Team Members</StatLabel>
                <Icon as={FaUsers} boxSize={7} color="black" />
              </Flex>
              <Flex justifyContent="space-between" alignItems="flex-end" mt={4}>
                <StatNumber fontSize="3xl">
                  {campaignStats.agentCount}
                </StatNumber>
                {isAdmin && (
                  <IconButton
                    size="sm"
                    onClick={() => setIsModalOpen(true)}
                    icon={<Icon as={FaPlus} />}
                    aria-label="Add Team Member"
                  />
                )}
              </Flex>
            </Stat>
          </SimpleGrid>

          <Box
            width="100%"
            bg="secondaryBackground"
            p={{ base: 4, md: 10 }}
            borderRadius="xl"
            boxShadow="md"
          >
            <VStack spacing={6} align="stretch">
              <Tabs variant="enclosed">
                <TabList>
                  <Tab>Businesses</Tab>
                  <Tab>Team Members</Tab>
                  <Tab>Supporters</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel px={0}>
                    <Box overflowX="auto">
                      <DataTable
                        columns={businessColumns}
                        data={businesses}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        highlightOnHover
                        striped
                        responsive
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box overflowX="auto">
                      <DataTable
                        columns={agentColumns}
                        data={agents}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        highlightOnHover
                        striped
                        responsive
                      />
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box overflowX="auto">
                      <DataTable
                        columns={customerColumns}
                        data={customers}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30]}
                        highlightOnHover
                        striped
                        responsive
                      />
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </Box>
          <Box display="flex" justifyContent="flex-start" mb={8}>
            {organizationId && (
              <Button
                leftIcon={<ArrowBackIcon />}
                variant="ghost"
                onClick={handleBackClick}
                size="sm"
              >
                Back to Organization
              </Button>
            )}
          </Box>
        </VStack>
      </Box>
      {addAgentModal}
      {editAgentModal}

      <Modal
        isOpen={isRemoveModalOpen}
        onClose={() => setIsRemoveModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Remove Team Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove {fundraiserToRemove?.name} from this
            campaign? This action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsRemoveModalOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmRemoveAgent}>
              Remove
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isErrorModalOpen}
        onClose={() => setIsErrorModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Error</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{errorMessage}</ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={() => setIsErrorModalOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={isDeleteBusinessModalOpen}
        onClose={() => setIsDeleteBusinessModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Business</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete {businessToDelete?.name?.en}? This
            action cannot be undone.
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsDeleteBusinessModalOpen(false)}
            >
              Cancel
            </Button>
            <Button colorScheme="red" onClick={confirmDeleteBusiness}>
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CampaignHome;
