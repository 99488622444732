import React, { useState, useEffect } from "react";
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  FormErrorMessage,
  Text,
  Link,
  Flex,
  Box,
  ButtonGroup,
  HStack,
  NumberInput,
  NumberInputField,
  Spinner,
} from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import PhoneNumberInput from "./PhoneNumberInput";
import axios from "axios";
import { apiUrl } from "../config";

const PurchaseForm = ({ campaignId, campaign, onClose, agentId }) => {
  const { language, translate } = useLanguage();
  const [agents, setAgents] = useState([]);
  const [donationAmount, setDonationAmount] = useState("0");
  const [customDonationAmount, setCustomDonationAmount] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const agentsRef = collection(db, "campaigns", campaignId, "agents");
        const agentsSnapshot = await getDocs(agentsRef);
        const agentsData = agentsSnapshot.docs.map((doc) => {
          const data = doc.data();
          const fullName = data.lastName
            ? `${data.firstName} ${data.lastName}`
            : data.firstName;
          return {
            id: doc.id,
            name: fullName,
          };
        });
        setAgents(agentsData);
      } catch (error) {
        console.error("Error fetching agents:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, [campaignId]);

  const initialValues = {
    campaignId: campaignId,
    agentId: agentId || "",
    name: "",
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    language: language,
    agreeToTerms: false,
    donationAmount: "0",
  };

  const validationSchema = Yup.object().shape({
    agentId: Yup.string().required(
      translate("addCustomer.validationErrors.fundraiserRequired")
    ),
    name: Yup.string().required(
      translate("addCustomer.validationErrors.nameRequired")
    ),
    email: Yup.string()
      .email(translate("addCustomer.validationErrors.invalidEmail"))
      .required(translate("addCustomer.validationErrors.emailRequired")),
    confirmEmail: Yup.string()
      .oneOf(
        [Yup.ref("email"), null],
        translate("addCustomer.validationErrors.emailsMustMatch")
      )
      .required(translate("addCustomer.validationErrors.confirmEmailRequired")),
    phoneNumber: Yup.string().matches(
      /^\d{10}$/,
      translate("addCustomer.validationErrors.invalidPhone")
    ),
    agreeToTerms: Yup.boolean()
      .oneOf(
        [true],
        translate("addCustomer.validationErrors.agreeToTermsRequired")
      )
      .required(translate("addCustomer.validationErrors.agreeToTermsRequired")),
    donationAmount: Yup.number()
      .min(0, translate("addCustomer.donation.error.invalid"))
      .transform((value) => (isNaN(value) ? undefined : value))
      .nullable(),
  });

  const handleSubmit = async (values, actions) => {
    try {
      const donationValue =
        values.donationAmount === "custom"
          ? parseFloat(customDonationAmount) || 0
          : parseFloat(values.donationAmount);

      const response = await axios.post(`${apiUrl}/create-checkout-session`, {
        ...values,
        donationAmount: donationValue,
      });

      if (response.status === 200) {
        window.location = response.data.url;
      }

      actions.setSubmitting(false);
      onClose();
    } catch (error) {
      console.error("Error processing purchase:", error);
      actions.setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Box textAlign="center" py={4}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form>
          <VStack spacing={4}>
            {!agentId && (
              <Field name="agentId">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={form.errors.agentId && form.touched.agentId}
                    isRequired
                  >
                    <FormLabel fontSize="sm">
                      {translate("addCustomer.fundraiser")}
                    </FormLabel>
                    <Select
                      {...field}
                      placeholder={translate("addCustomer.selectFundraiser")}
                    >
                      {agents.map((agent) => (
                        <option key={agent.id} value={agent.id}>
                          {agent.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{form.errors.agentId}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            <Field name="name">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.name && form.touched.name}
                  isRequired
                >
                  <FormLabel fontSize="sm">
                    {translate("addCustomer.name")}
                  </FormLabel>
                  <Input
                    {...field}
                    placeholder={translate("addCustomer.namePlaceholder")}
                  />
                  <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="email">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.email && form.touched.email}
                  isRequired
                >
                  <FormLabel fontSize="sm">
                    {translate("addCustomer.email")}
                  </FormLabel>
                  <Input
                    {...field}
                    placeholder={translate("addCustomer.emailPlaceholder")}
                  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="confirmEmail">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.confirmEmail && form.touched.confirmEmail
                  }
                  isRequired
                >
                  <FormLabel fontSize="sm">
                    {translate("addCustomer.confirmEmail")}
                  </FormLabel>
                  <Input
                    {...field}
                    placeholder={translate(
                      "addCustomer.confirmEmailPlaceholder"
                    )}
                  />
                  <FormErrorMessage>
                    {form.errors.confirmEmail}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="phoneNumber">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.phoneNumber && form.touched.phoneNumber
                  }
                >
                  <FormLabel fontSize="sm">
                    {translate("addCustomer.phoneNumber")}
                  </FormLabel>
                  <PhoneNumberInput field={field} />
                  <FormErrorMessage>{form.errors.phoneNumber}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {/* <Box w="100%">
              <Text fontSize="md" fontWeight="medium" mb={2}>
                {translate("addCustomer.donation.title")}
              </Text>
              <Text fontSize="sm" color="gray.600" mb={4}>
                {translate("addCustomer.donation.description")}
              </Text>
              <Field name="donationAmount">
                {({ field, form }) => (
                  <FormControl
                    isInvalid={
                      form.errors.donationAmount && form.touched.donationAmount
                    }
                  >
                    <ButtonGroup spacing={2} mb={4} width="100%">
                      <HStack spacing={2} wrap="wrap" width="100%">
                        {["0", "5", "10", "20", "custom"].map((value) => (
                          <Button
                            key={value}
                            flex="1"
                            maxW="80px"
                            variant={
                              field.value === value ? "solid" : "outline"
                            }
                            colorScheme="blue"
                            onClick={() => {
                              form.setFieldValue("donationAmount", value);
                              setDonationAmount(value);
                            }}
                          >
                            {value === "custom"
                              ? translate("addCustomer.donation.custom")
                              : `$${value}`}
                          </Button>
                        ))}
                      </HStack>
                    </ButtonGroup>
                    {donationAmount === "custom" && (
                      <NumberInput
                        mt={2}
                        min={0}
                        precision={2}
                        value={customDonationAmount}
                        onChange={(val) => setCustomDonationAmount(val)}
                      >
                        <NumberInputField
                          placeholder={translate(
                            "addCustomer.donation.customPlaceholder"
                          )}
                        />
                      </NumberInput>
                    )}
                    <FormErrorMessage>
                      {form.errors.donationAmount}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Box> */}

            <Field name="agreeToTerms">
              {({ field, form }) => (
                <FormControl
                  isInvalid={
                    form.errors.agreeToTerms && form.touched.agreeToTerms
                  }
                  isRequired
                >
                  <Flex alignItems="flex-start">
                    <input
                      type="checkbox"
                      {...field}
                      style={{ marginRight: "8px", marginTop: "4px" }}
                    />
                    <FormLabel fontSize="sm" mb={0}>
                      {translate("addCustomer.agreeToTerms")}{" "}
                      <Link
                        as={RouterLink}
                        to={`https://docs.coupal.io/terms/purchasers?lang=${language}`}
                        color="blue.500"
                        isExternal
                      >
                        {translate("addCustomer.termsAndConditions")}
                      </Link>{" "}
                      {translate("addCustomer.and")}{" "}
                      <Link
                        as={RouterLink}
                        to={`https://docs.coupal.io/privacy/purchasers?lang=${language}`}
                        color="blue.500"
                        isExternal
                      >
                        {translate("addCustomer.privacyPolicy")}
                      </Link>
                    </FormLabel>
                  </Flex>
                  <FormErrorMessage>
                    {form.errors.agreeToTerms}
                  </FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Button
              variant="primary"
              type="submit"
              isLoading={props.isSubmitting}
              width="100%"
              size="md"
            >
              {translate("addCustomer.proceedButton")}
            </Button>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};

export default PurchaseForm;
