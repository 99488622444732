import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Spinner,
  SimpleGrid,
  Text,
  Heading,
  Flex,
  Select,
} from "@chakra-ui/react";
import { db, storage } from "../firebase";
import { useLanguage } from "../contexts/LanguageContext";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import BusinessCard from "../components/BusinessCard";
import Navbar from "../components/Navbar";
import { FaRegClock, FaHeart, FaRegHeart } from "react-icons/fa";

const CustomerDashboard = () => {
  const { user } = useParams();
  const navigate = useNavigate();
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const [campaignSnap, setCampaignSnap] = useState(null);
  const [campaignId, setCampaignId] = useState(null);
  const { language, setLanguage, translate } = useLanguage();
  const [redemptions, setRedemptions] = useState({});
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [categories, setCategories] = useState([]);
  const [customerBusinesses, setCustomerBusinesses] = useState([]);
  const [showOnlyFavourites, setShowOnlyFavourites] = useState(false);
  const [isValidCustomer, setIsValidCustomer] = useState(false);

  useEffect(() => {
    const fetchCustomerAndData = async () => {
      try {
        if (!user) {
          setError("User parameter is required.");
          return;
        }

        // Fetch customer record
        const customerRef = doc(db, "customers", user);
        const customerSnap = await getDoc(customerRef);

        if (!customerSnap.exists()) {
          setError("Customer not found.");
          return;
        }

        // Set valid customer flag
        setIsValidCustomer(true);

        // Get redemptions and businessIds from customer data
        const customerData = customerSnap.data();
        setRedemptions(customerData.redemptions || {});
        const customerBusinesses = customerData.businessIds || [];

        // Store the customer's business data with favourite status
        setCustomerBusinesses(customerBusinesses);

        // Convert array to array of IDs if old format is found
        const customerBusinessIds = customerBusinesses.map((business) =>
          typeof business === "string" ? business : business.id
        );

        if (customerBusinessIds.length === 0) {
          setBusinesses([]);
          setLoading(false);
          return;
        }

        const customerCampaignId = customerSnap.data().campaignId;
        if (!customerCampaignId) {
          setError("No campaign associated with this customer.");
          return;
        }

        setCampaignId(customerCampaignId);

        // Fetch campaign name
        const campaignRef = doc(db, "campaigns", customerCampaignId);
        const campaignSnap = await getDoc(campaignRef);
        if (campaignSnap.exists()) {
          setCampaignName(campaignSnap.data().name);
          setCampaignSnap(campaignSnap);
        } else {
          setError("Campaign not found.");
          return;
        }

        // Fetch businesses using the customer's businessIds
        const businessPromises = customerBusinessIds.map(async (businessId) => {
          const businessDoc = await getDoc(doc(db, "businesses", businessId));

          if (!businessDoc.exists() || !businessDoc.data().approved) {
            return null;
          }

          const businessData = businessDoc.data();
          let logoUrl = null;

          try {
            const logoRef = ref(storage, `business-logos/${businessId}`);
            logoUrl = await getDownloadURL(logoRef);
          } catch (error) {
            console.error(
              `Error fetching logo for business ${businessId}:`,
              error
            );
          }

          return {
            id: businessId,
            ...businessData,
            logoUrl,
          };
        });

        const businessList = (await Promise.all(businessPromises))
          .filter((business) => business !== null)
          .map((business) => {
            // Find the matching business in customerBusinesses to get favourite status
            const customerBusiness = customerBusinesses.find(
              (cb) => (typeof cb === "string" ? cb : cb.id) === business.id
            );
            return {
              ...business,
              favourite:
                typeof customerBusiness === "string"
                  ? false
                  : customerBusiness?.favourite || false,
            };
          });

        // Extract unique categories - modified to handle both languages
        const uniqueCategories = [
          ...new Set(
            businessList.map((business) => {
              if (typeof business.category === "object") {
                return business.category[language] || business.category.en;
              }
              return business.category;
            })
          ),
        ].filter(Boolean);

        setCategories(uniqueCategories);
        setBusinesses(businessList);
      } catch (err) {
        console.error("Error fetching data: ", err);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerAndData();
  }, [user]);

  const toggleFavourite = async (businessId) => {
    if (!isValidCustomer) {
      console.error("Invalid customer access");
      return;
    }

    // Add basic rate limiting
    const now = Date.now();
    const lastToggle = localStorage.getItem(`lastToggle_${user}`);
    if (lastToggle && now - parseInt(lastToggle) < 1000) {
      // 1 second cooldown
      return; // Prevent rapid toggling
    }
    localStorage.setItem(`lastToggle_${user}`, now.toString());

    try {
      const customerRef = doc(db, "customers", user);
      const customerData = (await getDoc(customerRef)).data();

      // Find and update the business object in the array
      const updatedBusinessIds = customerData.businessIds.map((business) => {
        if (business.id === businessId) {
          return { ...business, favourite: !business.favourite };
        }
        return business;
      });

      // Update the document
      await updateDoc(customerRef, {
        businessIds: updatedBusinessIds,
      });

      // Update both local states
      setBusinesses((prevBusinesses) =>
        prevBusinesses.map((business) => {
          if (business.id === businessId) {
            return { ...business, favourite: !business.favourite };
          }
          return business;
        })
      );

      setCustomerBusinesses((prevBusinesses) =>
        prevBusinesses.map((business) => {
          if (business.id === businessId) {
            return { ...business, favourite: !business.favourite };
          }
          return business;
        })
      );
    } catch (error) {
      console.error("Error toggling favourite:", error);
    }
  };

  // Modify the filter function to include favourite filtering
  const filteredBusinesses = businesses.filter((business) => {
    // First check favourites filter
    if (showOnlyFavourites && !business.favourite) {
      return false;
    }
    // Then check category filter
    if (selectedCategory === "all") return true;

    if (typeof business.category === "object") {
      return (
        business.category.en === selectedCategory ||
        business.category.fr === selectedCategory
      );
    }
    return business.category === selectedCategory;
  });

  // Helper function to get the localized text with fallback
  const getLocalizedText = (field) => {
    if (typeof field === "object" && field !== null) {
      return field[language] || field.en || "";
    }
    return field || "";
  };

  if (loading) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      bg="primaryBackground"
    >
      <Navbar external={true} />

      <Box
        maxWidth="1200px"
        width="100%"
        margin="0 auto"
        px={{ base: 4, md: 6, lg: 8 }}
        pb={{ base: 6, md: 8, lg: 10 }}
      >
        <Box
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{ base: "center", md: "space-between" }}
          alignItems={{ base: "center", md: "flex-start" }}
          mb={{ base: 6, md: 8 }}
          mt={{ base: 16, md: 20 }}
        >
          <Box textAlign={{ base: "center", md: "left" }}>
            <Heading as="h1" size={{ base: "xl", md: "xl" }} mb={1}>
              {translate("businessList.heading")}
            </Heading>

            <Text fontSize={{ base: "xl", md: "xl", lg: "2xl" }} mb={4}>
              {getLocalizedText(campaignName)}
            </Text>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            alignItems={{ base: "center", md: "flex-end" }}
          >
            {campaignSnap?.data()?.redemptionStartDate &&
              campaignSnap?.data()?.redemptionEndDate && (
                <>
                  <Box w="100%" bg="gray.50" borderColor="gray.200" mt={3}>
                    <Flex justify="center" align="center" gap={2}>
                      <FaRegClock color="gray.600" size={20} />
                      <Text
                        fontSize={{ base: "sm", md: "md" }}
                        color="gray.600"
                      >
                        {translate("businessList.validFrom")}{" "}
                        <Text as="span" color="black" fontWeight="semibold">
                          {new Date(
                            campaignSnap
                              .data()
                              .redemptionStartDate?.toDate?.() ||
                              campaignSnap.data().redemptionStartDate
                          ).toLocaleDateString(
                            language === "fr" ? "fr-CA" : "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}
                        </Text>{" "}
                        {translate("businessList.to")}{" "}
                        <Text as="span" color="black" fontWeight="semibold">
                          {new Date(
                            campaignSnap.data().redemptionEndDate?.toDate?.() ||
                              campaignSnap.data().redemptionEndDate
                          ).toLocaleDateString(
                            language === "fr" ? "fr-CA" : "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}
                        </Text>
                      </Text>
                    </Flex>
                  </Box>
                  <Flex gap={2} align="center">
                    <Select
                      value={selectedCategory}
                      onChange={(e) => setSelectedCategory(e.target.value)}
                      bg="white"
                      maxW="200px"
                      size="sm"
                      mt={3}
                      focusBorderColor="black"
                      borderRadius="md"
                      display={{ base: "none", md: "block" }}
                    >
                      <option value="all">
                        {translate("businessList.allCategories")}
                      </option>
                      {categories.map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                    </Select>
                    <Box display={{ base: "none", md: "block" }} mt={3}>
                      {showOnlyFavourites ? (
                        <FaHeart
                          size={16}
                          onClick={() => setShowOnlyFavourites(false)}
                          cursor="pointer"
                          color="#E53E3E"
                        />
                      ) : (
                        <FaRegHeart
                          size={16}
                          onClick={() => setShowOnlyFavourites(true)}
                          cursor="pointer"
                          color="#718096"
                        />
                      )}
                    </Box>
                  </Flex>
                </>
              )}
          </Box>
        </Box>

        <Flex
          gap={2}
          align="center"
          display={{ base: "flex", md: "none" }}
          justify="center"
          w="100%"
          mb={4}
        >
          <Select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            bg="white"
            maxW="200px"
            size="sm"
            focusBorderColor="black"
            borderRadius="md"
          >
            <option value="all">
              {translate("businessList.allCategories")}
            </option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </Select>
          {showOnlyFavourites ? (
            <FaHeart
              size={16}
              onClick={() => setShowOnlyFavourites(false)}
              cursor="pointer"
              color="#E53E3E"
            />
          ) : (
            <FaRegHeart
              size={16}
              onClick={() => setShowOnlyFavourites(true)}
              cursor="pointer"
              color="#718096"
            />
          )}
        </Flex>

        <SimpleGrid
          columns={{ base: 1, sm: 2, xl: 3 }}
          spacing={{ base: 6, md: 8, lg: 10 }}
          mt={{ base: 10, md: 12 }}
          mx="auto"
          justifyItems="center"
          maxWidth="100%"
        >
          {filteredBusinesses.map((business) => (
            <BusinessCard
              key={business.id}
              redeemed={!!redemptions[business.id]}
              showFavourite={true}
              isFavourite={
                customerBusinesses.find((b) => b.id === business.id)
                  ?.favourite || false
              }
              onToggleFavourite={() => toggleFavourite(business.id)}
              business={{
                name: getLocalizedText(business.name),
                category: getLocalizedText(business.category),
                description: getLocalizedText(business.description),
                imageUrl: business.logoUrl,
                websiteUrl: business.websiteUrl,
                googleProfileUrl: business.googleProfileUrl,
                coupon: {
                  title: getLocalizedText(business.offerTitle),
                  description: getLocalizedText(business.offerDescription),
                  termsAndExclusions: getLocalizedText(
                    business.termsAndExclusions
                  ),
                  maxValue: business.maxValue,
                },
              }}
              translations={{
                language,
                couponDetails: translate("businessList.couponDetails"),
                visitWebsite: translate("businessList.website"),
                googleProfile: translate("businessList.googleProfile"),
                termsAndExclusions: translate(
                  "businessList.termsAndExclusions"
                ),
                maxValue: translate("businessList.maxValue"),
              }}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default CustomerDashboard;
