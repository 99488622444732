import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { db, storage } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { useLanguage } from "../contexts/LanguageContext";
import BusinessCard from "../components/BusinessCard";
import Navbar from "../components/Navbar";
import PurchaseForm from "../components/PurchaseForm";
import { FaShoppingCart, FaRegClock } from "react-icons/fa";
import { Spinner } from "@chakra-ui/react";

const PurchasePage = () => {
  const { campaignId, agentId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { language, translate } = useLanguage();
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [campaign, setCampaign] = useState(null);

  const getLocalizedText = (field) => {
    if (typeof field === "object" && field !== null) {
      return field[language] || field.en || "";
    }
    return field || "";
  };

  useEffect(() => {
    const fetchCampaignAndBusinesses = async () => {
      try {
        const campaignRef = doc(db, "campaigns", campaignId);
        const campaignSnap = await getDoc(campaignRef);
        if (campaignSnap.exists()) {
          setCampaign(campaignSnap.data());
        } else {
          setError("Campaign not found.");
          return;
        }

        const businessesRef = collection(db, "businesses");
        const q = query(
          businessesRef,
          where("campaignId", "==", campaignId),
          where("approved", "==", true)
        );
        const querySnapshot = await getDocs(q);
        const businessList = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const businessData = doc.data();
            const businessId = doc.id;
            let logoUrl = null;

            try {
              const logoRef = ref(storage, `business-logos/${businessId}`);
              logoUrl = await getDownloadURL(logoRef);
            } catch (error) {
              console.error(
                `Error fetching logo for business ${businessId}:`,
                error
              );
            }

            return {
              id: businessId,
              ...businessData,
              logoUrl,
            };
          })
        );
        setBusinesses(businessList);
      } catch (err) {
        console.error("Error fetching data: ", err);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchCampaignAndBusinesses();
  }, [campaignId]);

  if (loading) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner size="xl" color="secondary" />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        minH="100vh"
        bg="primaryBackground"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color="red.500">{error}</Text>
      </Box>
    );
  }

  return (
    <Box
      minH="100vh"
      display="flex"
      flexDirection="column"
      bg="primaryBackground"
    >
      <Navbar external={true} />

      <Container
        maxW="1200px"
        px={{ base: 4, md: 6, lg: 8 }}
        py={{ base: 8, md: 12 }}
      >
        {/* Header Section */}
        <Box width="100%" mb={12} textAlign={{ base: "center", md: "left" }}>
          <Heading as="h1" size="xl" mb={4}>
            {getLocalizedText(campaign.name)}
          </Heading>

          <Text fontSize="lg" color="gray.600" mb={8}>
            {getLocalizedText(campaign.description)}
          </Text>

          {/* Purchase Info Section */}
          <Flex
            direction="column"
            align={{ base: "center", md: "start" }}
            justify={{ base: "center", md: "start" }}
            textAlign={{ base: "center", md: "left" }}
            mb={8}
          >
            <Box mb={4}>
              <Text fontSize="4xl" fontWeight="bold">
                ${campaign.couponBookPrice}
              </Text>
            </Box>

            <Button
              onClick={onOpen}
              size="md"
              variant="primary"
              leftIcon={<FaShoppingCart />}
              minW="200px"
            >
              {translate("businessList.goToPurchase")}
            </Button>

            {/* Campaign Dates */}
            {campaign?.redemptionStartDate && campaign?.redemptionEndDate && (
              <Flex
                align={{ base: "center", md: "start" }}
                justify={{ base: "center", md: "start" }}
                gap={2}
                mt={4}
                width="100%"
              >
                <FaRegClock color="gray.600" />
                <Text fontSize="sm" color="gray.600">
                  {translate("businessList.validFrom")}{" "}
                  <Text as="span" fontWeight="semibold">
                    {new Date(
                      campaign.redemptionStartDate?.toDate?.() ||
                        campaign.redemptionStartDate
                    ).toLocaleDateString(
                      language === "fr" ? "fr-CA" : "en-US",
                      {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }
                    )}
                  </Text>{" "}
                  {translate("businessList.to")}{" "}
                  <Text as="span" fontWeight="semibold">
                    {new Date(
                      campaign.redemptionEndDate?.toDate?.() ||
                        campaign.redemptionEndDate
                    ).toLocaleDateString(
                      language === "fr" ? "fr-CA" : "en-US",
                      {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                      }
                    )}
                  </Text>
                </Text>
              </Flex>
            )}
          </Flex>

          <Divider borderColor="gray.300" mb={8} />

          {/* Available Savings Header */}
          <Heading
            as="h2"
            size="md"
            mb={6}
            textAlign={{ base: "center", md: "left" }}
          >
            {translate("businessList.availableSavings")}
          </Heading>

          {/* Business Cards Grid */}
          <SimpleGrid
            columns={{ base: 1, sm: 2, xl: 3 }}
            spacing={{ base: 6, md: 8, lg: 10 }}
            width="100%"
          >
            {businesses.map((business) => (
              <BusinessCard
                key={business.id}
                business={{
                  name: getLocalizedText(business.name),
                  category: getLocalizedText(business.category),
                  description: getLocalizedText(business.description),
                  imageUrl: business.logoUrl,
                  websiteUrl: business.websiteUrl,
                  googleProfileUrl: business.googleProfileUrl,
                  coupon: {
                    title: getLocalizedText(business.offerTitle),
                    description: getLocalizedText(business.offerDescription),
                    termsAndExclusions: getLocalizedText(
                      business.termsAndExclusions
                    ),
                    maxValue: business.maxValue,
                  },
                }}
                translations={{
                  language,
                  couponDetails: translate("businessList.couponDetails"),
                  visitWebsite: translate("businessList.website"),
                  googleProfile: translate("businessList.googleProfile"),
                  termsAndExclusions: translate(
                    "businessList.termsAndExclusions"
                  ),
                  maxValue: translate("businessList.maxValue"),
                }}
              />
            ))}
          </SimpleGrid>
        </Box>

        {/* Purchase Form Modal */}
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent p={2}>
            <ModalHeader>{translate("addCustomer.heading")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <PurchaseForm
                campaignId={campaignId}
                campaign={campaign}
                onClose={onClose}
                agentId={agentId}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Container>
    </Box>
  );
};

export default PurchasePage;
